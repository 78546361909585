/**
 * 加工时间格式
 * @param {Object} date 
 */
export default function setTime(date, type) {

  if (type == 'sendLid') {  //活动ID
    return 'LID' + new Date().getTime() + '' + Math.floor(Math.random() * 10)
  } else if (type == 'sendPid') {
    return 'PID' + new Date().getTime() + '' + date
  }

  // let newDate = new Date()
  let newDate = date,
      str = '';
  if (type == '2') {
    newDate = new Date(Number(date))
  }

  let fullYear = newDate.getFullYear()
  let mon = newDate.getMonth() + 1
  let day = newDate.getDate() + 1
  let hou = newDate.getHours()
  let min = newDate.getMinutes()
  let sec = newDate.getSeconds()

  if (type == '1') {  //xxxx-xx-xx xx:xx:xx
    return str = 
    fullYear + '-' + 
    ((mon < 10) ? '0' + mon : mon)  + '-' + 
    ((day < 10) ? '0' + day : day) + ' ' + 
    ((hou < 10) ? '0' + hou : hou) + ':' + 
    ((min < 10) ? '0' + min : min) + ':' +
    ((sec < 10) ? '0' + sec : sec)
  } else if (type == '2') {
    return str = 
    fullYear + '-' + 
    ((mon < 10) ? '0' + mon : mon)  + '-' + 
    ((day < 10) ? '0' + day : day) + ' ' + 
    ((hou < 10) ? '0' + hou : hou) + ':' + 
    ((min < 10) ? '0' + min : min)
    
  }
}
