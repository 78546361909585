<template>
  <div id="app">
    <HeadView v-show="islayout" ref="headRef" />
    <router-view />
  </div>
</template>

<script>
import HeadView from "@/components/HeadView.vue"
export default {
  name: 'app',
  components: {
    HeadView
  },
  data() {
    return {
      islayout: true,
    }
  },
  watch: {
      $route(to, from) {
        if (to.path.indexOf("login") >= 0) {
          this.islayout = false
        } else {
          let key = 0
          switch (to.path) {
            case '/about':
              key = 1
              break;
            case '/add':
              key = 2
              break;
          }
          this.$refs.headRef.setClass(key)
          this.islayout = true
        }
      },
  },
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
</style>
