<template>
    <div class="home">
        <div class="body">
            <div class="add">
                <div class="button">
                    <el-button type="primary" @click="openBox()"
                        >发布新活动</el-button
                    >
                </div>
                <div class="search">
                    <el-input 
                        v-model="searchVal" 
                        placeholder="请输入活动标题" 
                        prefix-icon="el-icon-search">
                    </el-input>
                </div>
                <div class="searchBtn">
                    <el-button type="primary" 
                        @click="search()">
                        搜索
                        </el-button>
                     <el-button type="success" 
                        @click="reset()">
                        刷新
                        </el-button>
                </div>
            </div>
            <div class="showBox">
                <el-table :data="tableData" style="width: 100%" height="75vh" v-loading="loading">
                     
                    <el-table-column
                        align="center"
                        label="活动标题"
                        width="150"
                        prop="ltitle"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="活动秘钥"
                        width="150"
                        prop="lpassWord"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="开始时间"
                        width="180"
                        prop="timeData"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="结束时间"
                        width="180"
                        prop="endTime"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="人数上限"
                        width="120"
                        prop="lpeople"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="已参与人数"
                        width="120"
                        prop="peopleNum"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="奖品数量"
                        width="120"
                        prop="lprizeNum"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="活动状态"
                        width="120"
                        prop="isEnd"
                    ></el-table-column>
                    <el-table-column
                        width="240"
                        align="center"
                        label="活动规则"
                    >
                        <template slot-scope="scope">
                            <div class="ruleBox">
                                {{ scope.row.lrule }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column
                        align="center"
                        label="操作"
                        width="220"
                        fixed="right"
                    >
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                @click="handleEdit(scope.$index, scope.row)"
                                >详情</el-button
                            >
                            <el-button
                                v-show="scope.row.lend == '0'"
                                size="mini"
                                type="warning"
                                @click="handleEnd(scope.$index, scope.row)"
                                >结束</el-button
                            >
                            <el-button
                                size="mini"
                                type="danger"
                                @click="handleDelete(scope.$index, scope.row)"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>

        <div class="addLuckDrawMsg" v-if="showBox" @click.stop="closeBox()">
            <div
                class="addBox moveMsg"
                @click.stop="openMsgBox()"
                v-if="moveMsgBox"
            >
                <div class="timeAndOver">
                    <i
                        >{{ moveMsgObj.timeData }}发布 &nbsp;&nbsp;
                        {{ moveMsgObj.isEnd }}</i
                    >
                </div>
                <div class="boxTitle">-- 活动详情 --</div>

                <div class="move">
                    <div class="moveTitle">
                        <div class="inputText">活动标题：</div>
                        <div class="inputFont">
                            <el-input
                                v-model="moveMsgObj.ltitle"
                                placeholder="暂无活动标题"
                                :disabled="true"
                            />
                        </div>
                        <div class="inputText">活动秘钥：</div>
                        <div class="inputFont">
                            <el-input
                                v-model="moveMsgObj.lpassWord"
                                :disabled="true"
                            />
                        </div>
                    </div>
                    <div class="moveTitle">
                        <div class="inputText">人数上限：</div>
                        <div class="inputFont">
                            <el-input-number
                                v-model="moveMsgObj.lpeople"
                                :min="1"
                                :max="9999"
                                :disabled="true"
                                label="请输入参与活动的人数上限"
                            ></el-input-number>
                        </div>
                        <div class="inputText">奖品数量：</div>
                        <div class="inputFont">
                            <el-input-number
                                v-model="moveMsgObj.lprizeNum"
                                @change="handleChange(addMoveData.moveNum)"
                                :min="1"
                                :max="15"
                                label="描述文字"
                                :disabled="true"
                            ></el-input-number>
                        </div>
                    </div>

                    <div class="addMoveImg">
                        <div
                            class="imgBox"
                            v-for="(item, key) in moveMsgObj.fileList"
                            :key="key"
                        >
                            <img :src="item" alt="" />
                            <div class="ico">
                                <span>奖品{{ key + 1 }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="moveTitle">
                        <div class="inputText">活动备注：</div>
                        <div class="moveTextCenten">
                            <el-input
                                type="textarea"
                                :rows="5"
                                placeholder="活动备注"
                                maxlength="1000"
                                resize="none"
                                show-word-limit
                                v-model="moveMsgObj.lrule"
                                :disabled="true"
                            >
                            </el-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import setTime from "../utils/index";

export default {
    name: "HomeView",
    data() {
        return {
            showBox: false,
            addMoveData: {
                moveTitle: "",
                moveKey: "",
                movePeople: 500,
                moveNum: 1,
                moveRule: "",
                moveImgArr: [],
            },
            fileList: [],
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,

            addMoveBox: false, //发布新活动
            moveMsgBox: false, //活动详情
            moveMsgObj: {},

            tableData: [],
            loading: true,
            currentPage: 1,
            pageSizes: [10, 20, 30, 40],
            pageSize: 10,
            total: 0,
            searchVal: '',
        };
    },
    
    mounted() {
        this.getLuckDrawAll();
    },

    methods: {
        getUserInfo() {
            // this.request.get(this.baseUrl + "/user/userInfo").then((res) => {
            //   console.log(res);
            // });
        },

        getLuckDrawAll() {
            this.loading = true
            let data = {
                pageSize: this.pageSize,
                currentPage: this.currentPage,
                searchVal: this.searchVal
            }
            //获取所有抽奖活动
            this.request
                .post(this.baseUrl + "/draw/luckdraw", data)
                .then((res) => {
                    this.loading = false
                    if (res.code == 200) {
                        let data = [];
                        this.total = res.data.total
                        res.data.data.forEach((item, key) => {
                            data.push(item);
                            data[key]["timeData"] = setTime(
                                item.lstartTime,
                                "2"
                            );
                            data[key]["isEnd"] =
                                item.lend == "1" ? "已结束" : "正在进行中";
                            if (item.lendtime) {
                                data[key]["endTime"] = setTime(
                                new Date(item.lendtime),
                                "2"
                            );
                            } else {
                                data[key]["endTime"] = '-'
                            }
                        });
                        this.tableData = data
                    } else {
                        this.loading = false
                        this.$message({
                            type: "error",
                            message: res.message,
                        });
                    }
                })
                .catch((err) => {
                    this.$message({
                        type: "error",
                        message: res.message,
                    });
                });
        },

        handleEdit(index, row) {
            this.moveMsgObj = row;
            this.moveMsgObj.fileList = [];
            this.moveMsgObj.data.forEach((item, key) => {
                this.moveMsgObj["fileList"].push(this.baseUrl + item.pimg);
            });

            this.moveMsgBox = true;
            this.showBox = true;
        },

        handleDelete(index, row) {
            //删除活动

            this.$confirm("此操作将会永久删除该活动，是否继续？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.request
                        .get(this.baseUrl + `/draw/delluckdraw?lid=${row.lid}`)
                        .then((res) => {
                            if (res.code == "200") {
                                this.$message({
                                    type: "success",
                                    message: "成功删除",
                                });
                                setTimeout(() => {
                                    this.$router.go(0);
                                }, 1000);
                            } else {
                                this.$message({
                                    type: "error",
                                    message: "成功删除",
                                });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            this.$message({
                                type: "error",
                                message: err,
                            });
                        });
                })
                .catch((err) => {});
        },

        handleEnd(index, row) {
            //结束活动

            this.$confirm("结束活动操作将不可逆，是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.request
                        .get(this.baseUrl + `/draw/endluckdraw?lid=${row.lid}`)
                        .then((res) => {
                            if (res.code == "200") {
                                this.tableData[index]["lend"] = 1;
                                this.tableData[index]["isEnd"] = "已结束";
                                this.$message({
                                    type: "success",
                                    message: "修改成功!",
                                });
                            } else {
                                this.$message({
                                    type: "warning",
                                    message: res.message,
                                });
                            }
                        })
                        .catch((err) => {
                            this.$message({
                                type: "error",
                                message: err,
                            });
                        });
                })
                .catch((err) => {});
        },

        closeBox() {
            //关闭所有模态框
            this.addMoveBox = false;
            this.showBox = false;
            this.moveMsgBox = false;
        },

        openBox() {
            //发布活动
            this.$router.push("/add");
        },

        openMsgBox() {
            this.moveMsgBox = true;
            this.showBox = true;
        },

        handleChange(moveNum) {
            if (moveNum < this.fileList.length) {
                let len = this.fileList.length - moveNum;
                for (let x = 0; x < len; x++) {
                    this.fileList.splice(this.fileList.length - 1, 1);
                }
            }
        },

        confirmAdd() {
            //确认添加活动

            let lid = setTime("", "sendLid");
            if (!this.addMoveData.moveKey.trim()) {
                return this.$message({
                    type: "warning",
                    message: "活动秘钥不能为空!",
                });
            } else if (this.fileList.length != this.addMoveData.moveNum) {
                return this.$message({
                    type: "warning",
                    message: "请添加等数量的奖品图片!",
                });
            } else {
                this.addMoveData.moveTitle = this.addMoveData.moveTitle.trim()
                    ? this.addMoveData.moveTitle
                    : `第${Number(this.tableData.length) + 1}次活动`;
            }
            let upImgArr = [];
            for (let x = 0; x < this.fileList.length; x++) {
                let pid = setTime(x, "sendPid");
                upImgArr.push({
                    pid: pid,
                    img: this.fileList[x],
                });
            }
            let data = {
                lid: lid,
                title: this.addMoveData.moveTitle,
                moveKey: this.addMoveData.moveKey,
                movePeople: this.addMoveData.movePeople,
                moveNum: this.addMoveData.moveNum,
                moveRule: this.addMoveData.moveRule,
                moveTime: new Date().getTime(),
                imgdata: upImgArr,
            };

            this.request
                .post(this.baseUrl + "/draw/uploadimg", data)
                .then((res) => {
                    if (res.code == "200") {
                        this.$message({
                            type: "success",
                            message: "发布成功",
                        });
                        setTimeout(() => {
                            this.$router.go(0);
                        }, 200);
                    }
                });
        },

        //上传图片
        uploadImg(e) {
            // this.fileList.push(files)
            this.addMoveData.moveImgArr.push(e.target.files[0]);
            let _this = this;
            let fI = new FileReader();
            fI.readAsDataURL(e.target.files[0]);
            fI.onload = function (ev) {
                _this.fileList.push(this.result);
            };
        },

        removeImg(item, key) {
            //删除已添加的商品图片
            this.fileList.splice(key, 1);
        },

        closeAddMove() {
            //取消发布
            this.$confirm("以编辑的内容将会丢失，是否确定取消发布?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.addMoveData = {
                        moveTitle: "",
                        moveKey: "",
                        movePeople: 500,
                        moveNum: 1,
                        moveRule: "",
                        moveImgArr: [],
                    };
                    this.addMoveBox = false;
                    this.showBox = false;
                    this.fileList = [];
                })
                .catch(() => {});
        },

        handleSizeChange(e) {
            this.pageSize = e
            this.currentPage = 1
            this.getLuckDrawAll()
        },

        handleCurrentChange(e) {
            this.currentPage = e
            this.getLuckDrawAll()
        },

        search() {
            this.getLuckDrawAll()
        },

        reset() {
            this.searchVal = ''
            this.currentPage = 1
            this.pageSize = 10
            this.getLuckDrawAll()
        },
    },
};
</script>

<style lang="less" scoped>
.body {
    margin: 50px 0 0 210px;
    padding: 10px;
    .add {
        display: flex;
        flex-direction: row;
        padding: 5px;

        .button {
            width: 200px;
        }

        .search {
            width: 300px;
        }

        .searchBtn {
            padding-left: 20px;

            /deep/ .el-button {
                border: 1px solid #409eff;
                background-color: #409eff;
            }
        }
    }
    .showBox {
        margin: 10px auto 0;
        padding: 5px;
        width: 95%;
        height: 75vh;
        overflow: hidden;
        overflow-y: auto;
        border: 1px solid #efefef;
        box-shadow: 0 1px 4px rgb(0 21 41 / 10%);
    }
}

.addLuckDrawMsg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;

    .addBox {
        position: relative;
        margin: calc((100vh - 500px) / 2) auto 0;
        width: 750px;
        height: 500px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

        .boxTitle {
            width: 100%;
            height: 45px;
            font-size: 18px;
            font-weight: bold;
            line-height: 45px;
            text-align: center;
        }

        .timeAndOver {
            position: absolute;
            left: 10px;
            top: 10px;
            font-size: 13px;
        }
    }
}

.move {
    padding: 10px;
    .moveTitle {
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        .inputText {
            padding-left: 20px;
            text-align: right;
            height: 40px;
            line-height: 40px;
        }
    }

    .addMoveImg {
        margin: 0 auto;
        padding-top: 20px;
        width: 90%;
        height: 148px;
        overflow-x: hidden;

        .addImg {
            float: left;
            position: relative;
            width: 120px;
            height: 120px;
            border: 1px solid #ccc;
            overflow: hidden;
            i {
                display: block;
                position: absolute;
                width: 15px;
                height: 15px;
                background-size: 100%;
                top: 52px;
                left: 52px;
            }
            input {
                opacity: 0;
                width: 100%;
                height: 100%;
            }
        }

        .imgBox {
            margin-right: 5px;
            margin-bottom: 5px;
            position: relative;
            float: left;
            width: 120px;
            height: 120px;
            font-size: 14px;
            text-align: center;
            border-radius: 8px;
            overflow: hidden;
            img {
                width: 100%;
            }

            .ico {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 120px;
                height: 120px;
                line-height: 120px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.3);
                cursor: pointer;
            }
        }
    }

    .imgBox:hover .ico {
        display: block;
    }

    .moveTextCenten {
        width: 75%;

        input {
            width: 100%;
        }
    }

    .confirmAdd {
        padding-top: 15px;
        text-align: center;
    }
}

.ruleBox {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.pagination {
    margin:  0 auto;
    padding: 5px;
    width: 95%;

    /deep/ .el-pagination {
        float: right;
    }
}
</style>